import React, { useEffect } from 'react';
import cx from 'classnames';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Loader from '~components/loader';

import CardSkeleton from '~components/card/skeleton';

import { isVerified } from '~helpers/common';
import NoResults from './no-results';
import ResultsItem from './results-item';

const SKELETON_COUNT = 6;

const ResultsList = ({ matches = [], count, locationId, isLoading, onShowMore = () => {}, isLoadingMore }) => {
  const [sentryRef, { listRef }] = useInfiniteScroll({
    loading: isLoadingMore,
    hasNextPage: count > matches?.length,
    onLoadMore: onShowMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: false,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    if (isLoading && listRef && listRef.current) {
      listRef.current.scrollTo(0, 0);
    }
  }, [isLoading]);

  return (
    <div
      className={cx('c-matches-list c-matches-list--narrow', {
        'c-matches-list--no-results': matches?.length === 0,
      })}
    >
      <div className="c-matches-list__results" ref={listRef}>
        {isLoading ? (
          <CardSkeleton skeletonCount={SKELETON_COUNT} />
        ) : matches && matches.length > 0 ? (
          <>
            {matches.map((match) => (
              <ResultsItem
                personId={match.personId}
                key={match.personId}
                locationId={locationId}
                interval={match.interval}
                duration={match.duration}
                days={match.days}
                location={match.location}
                firstName={match.firstName}
                profilePicture={match.profilePicture}
                verification={isVerified(match.verification)}
                personalNote={match.personalNote}
                lastLogin={match.lastLogin}
                isOnline={match.isOnline}
                registerDate={match.registerDate}
                score={match.score}
                published={match.published}
                showFavorite
              />
            ))}
            {(matches.length < count || isLoadingMore) && (
              <div className="c-infinite-scroll" ref={sentryRef}>
                <Loader />
              </div>
            )}
          </>
        ) : (
          <NoResults />
        )}
      </div>
    </div>
  );
};

export default ResultsList;
