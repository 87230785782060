import React, { useMemo, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { FiShare2, FiMapPin } from 'react-icons/fi';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useStateFromProp } from '~hooks/common';
import { useGet } from '~hooks/useQuery';
import { getDistanceFormatted, nl2br, renderFirstName } from '~helpers/common';
import { UserContext } from '~contexts/user';

import Modal from '~components/modal';
import Avatar from '~components/avatar';
import ReviewsList from '~components/review/list';
import Stars from '~components/stars';
import LastOnline from '~components/last-online';
import VerificationBadge from '~components/verification/badge';
import Button from '~components/button';
import Share from '~components/share';
import Favorite from '~components/favorite';

import '../../style.scss';
import '../style.scss';

const HOUR = 3600;

const ProfileModal = ({
  personId,
  firstName,
  profilePicture,
  personalNote,
  location,
  score,
  interval,
  duration,
  days,
  lastLogin,
  isOnline,
  registerDate,
  verification,
  isOpened,
  published,
  onToggle = () => {},
  onFavorite = () => {},
}) => {
  if (!isOpened) {
    return null;
  }

  const intl = useIntl();
  const history = useHistory();

  const domainConfig = useSelector((state) => state.domainConfig);
  const { user } = useContext(UserContext);
  const userLocation = user.accountType === 'worker' ? user?.operations?.address : user?.wish?.location;
  const fName = renderFirstName(firstName);

  const [isModalOpened, setModalOpen] = useStateFromProp(isOpened, onToggle);
  const [isShareOpened, setShareOpen] = useState(false);

  const distance = useMemo(
    () =>
      getDistanceFormatted(location?.latitude, location?.longitude, userLocation?.latitude, userLocation?.longitude),
    [location, userLocation]
  );

  const { data: reviews, isLoading: isLoadingReviews } = useGet(
    {
      name: `reviews`,
      requiresAuthentication: false,
      shouldExecute: !!personId && isModalOpened,
      mockFileName: 'reviews/get-by-receiver',
      params: {
        'receiver-id': personId,
        // limit: 10,
        // offset: 0,
      },
    },
    [personId]
  );

  const handleChat = () => {
    history.push({ pathname: `/discussion/${personId}` });
  };

  return (
    <Modal.Wrapper isOpened={isModalOpened} onClose={() => setModalOpen()} className="c-profile c-profile--modal">
      <Button className="c-profile__share" onClick={() => setShareOpen(true)}>
        <FiShare2 />
      </Button>
      <div className="c-profile__picture">
        <Avatar profilePicture={profilePicture} firstName={fName} size="large" />
      </div>
      <Modal.Content>
        <div className="c-profile__picture--mobile">
          <Avatar profilePicture={profilePicture} firstName={fName} size="large" />
        </div>
        <div className="c-profile__header">
          <div className="c-profile__name l-headline--secondary notranslate">
            {fName}
            <VerificationBadge isVerified />
          </div>
          <div className="c-profile__score">
            <Stars value={score?.score || 4} />
            {/* <Stars value={score?.score} disabled={!score || !score.score} /> */}
            {score && score.votes > 0 ? <div className="c-profile__votes">({score.votes})</div> : null}
          </div>
          {/* ADDRESS */}
          <div className="c-profile__address">
            <FiMapPin />
            <span>{`${[location?.city, location?.country].filter(Boolean).join(', ')} ${
              distance ? `(${distance} km)` : ''
            }`}</span>
          </div>
        </div>
        <div className="c-profile__features">
          <div className="c-profile__feature">
            <div className="c-profile__key">
              {intl.formatMessage({
                id: 'containers.profile.customer.active-since.label',
                defaultMessage: 'Active since',
              })}
            </div>
            <div className="c-profile__value">
              {registerDate
                ? intl.formatDate(moment.unix(registerDate).toDate(), {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                : '-'}
            </div>
          </div>
          <div className="c-profile__feature">
            <div className="c-profile__key">
              {intl.formatMessage({
                id: 'containers.profile.customer.last-online.label',
                defaultMessage: 'Last online',
              })}
            </div>
            <div className="c-profile__value">
              <LastOnline lastOnline={lastLogin} isOnline={isOnline} isPublished={published} />
            </div>
          </div>
          <div className="c-profile__feature">
            <div className="c-profile__key">
              {intl.formatMessage({ id: 'containers.profile.customer.service.label', defaultMessage: 'Service' })}
            </div>
            <div className="c-profile__value">
              {`${intl.formatMessage({ id: `interval.every-${interval}-weeks` })}, ${intl.formatMessage(
                {
                  id: 'containers.profile.customer.duration',
                  defaultMessage: '{duration, plural, =1 {# hour} other { # hours}}',
                },
                { duration: duration / HOUR }
              )}`}
            </div>
          </div>
          <div className="c-profile__feature">
            <div className="c-profile__key">
              {intl.formatMessage({ id: 'containers.profile.customer.days.label', defaultMessage: 'Possible days' })}
            </div>
            <div className="c-profile__value">
              {days.map(
                (d, index) => `${intl.formatMessage({ id: `days.short.${d}` })}${index !== days.length - 1 ? ', ' : ''}`
              )}
            </div>
          </div>
        </div>
        <div className="c-profile__about">
          <div className="l-headline--tertiary">
            {intl.formatMessage({ id: 'containers.profile.customer.about', defaultMessage: 'About' })}
          </div>
          <p>{personalNote ? nl2br(personalNote) : '-'}</p>
        </div>

        <ReviewsList
          isLoading={isLoadingReviews}
          reviews={reviews?.data}
          className="c-profile__reviews"
          headline={intl.formatMessage({ id: 'containers.profile.customer.reviews', defaultMessage: 'Customer reviews' })}
        />

        <div className="c-profile__footer">
          <div className="c-profile__bottom-buttons">
            <Button onClick={handleChat}>
              {intl.formatMessage({ id: 'containers.profile.customer.buttons-bottom.chat', defaultMessage: 'Chat' })}
            </Button>
            <Favorite accountType="client" personId={personId} onChange={onFavorite} />
          </div>
        </div>
      </Modal.Content>
      <Share
        sharedUrl={`${domainConfig.protocol}://${domainConfig.domain}/customer/${personId}`}
        title={intl.formatMessage(
          {
            id: 'containers.profile.seo.customer.share-title',
            defaultMessage: '{firstName} cleaner profile - #website',
          },
          {
            firstName: fName,
          }
        )}
        isOpened={isShareOpened}
        onToggle={() => setShareOpen(false)}
      />
    </Modal.Wrapper>
  );
};

export default ProfileModal;
